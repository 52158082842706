@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF8E7;
  color: #4A3728;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  border-radius: 8px;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #2C5F2D;
  border-color: #2C5F2D;
}

.btn-primary:hover {
  background-color: #234C24;
  border-color: #234C24;
}

.input {
  border-radius: 8px;
}

/* Hide reCAPTCHA badge */
.grecaptcha-badge {
  visibility: hidden;
}